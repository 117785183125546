$card-max-width: 500px;
$card-min-width: 200px;
$sidebar-max-width: 300px;
$sidebar-min-width: 200px;

$navbar-height: 80px;
$hero-height: 500px;
$page-header: 300px;

$border-radius: 10px;

//====================== font========================
$font-family: 'Readex Pro', 'Roboto', sans-serif;

//====================== color=======================
$primary: #353f59;
$secondary: #7d8695;

$white: #fff;
$black: #000;

$blue-light: #55596c;

$gray-bold: #55596c;

$red-bold: #E71C61;

$yellow: #fa7b07;

// ===================== font size ======================

