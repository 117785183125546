@use 'assets/styles/' as *;

.navbar{
  height: $navbar-height;
	display: flex;

	& > * ~ * {
		margin-left: 20px;
	}

	&__logo{
		@include flex(unset, center);

		&--link{
			@include flex(unset, center);
		}
		
		img{
			height: calc($navbar-height / 2);
		}
	}

  &__list {
		height: 100%;
		@include flex(unset, center);

		& > * ~ * {
			margin-left: 15px;
		}

		&__item {
			position: relative;
			&::after {
				content: '';

				position: absolute;
				width: 0%;
				left: 50%;
				bottom: 0;

				border-bottom: 2px solid $secondary;

				transform: translateX(-50%);

				transition: width 0.3s ease;
			}

      @media (hover : hover){
        &:hover::after{
          width: 100%;
        }
      }

			&__link {
				display: inline-block;

				color: $secondary;
        font-weight: 500;

				padding: 5px 0;

        &__active{
          color: $primary;
        }
			}
		}
	}
}