@use 'assets/styles/' as *;

.videos-list{
  & > * ~ * {
    margin-top: 1rem;
  }

  &__item{
    &__title{
      font-size: 1.25rem;
    }
    &__description{
      font-size: .8rem;
      color: $secondary;
    }
  }
}