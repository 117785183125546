@use 'assets/styles/' as *;

.detail-page {
	position: relative;

	&__content {
		padding: 1.5rem 0;
		& > * ~ * {
			margin-top: 2rem;
		}
	}
	
	&__section {
		& > * ~ * {
			margin-top: 0.75rem;
		}
	}
}

.genres__list {
	@include flex(unset, center);

	font-size: 0.75rem;
	color: $white;
	width: 80%;

	gap: 7px;
	flex-wrap: wrap;

	z-index: 1;

	&__item {
		border-radius: 999px;

		padding: 5px 7px;

		position: relative;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			background-color: currentColor;
			opacity: 0.5;

			border-radius: inherit;
		}
	}
}
