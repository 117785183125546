@use 'assets/styles/' as *;

.hero {
	&-item {
		position: relative;
		width: 100%;
		height: $hero-height;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;

			border-radius: $border-radius;

			background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.95) 100%);
		}
		&__image {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;

			border-radius: $border-radius;
		}

		&__content {
			position: absolute;
			top: 50%;
			left: 5%;
			width: 70%;

			transform: translateY(100%);
			transition: all 1.5s ease;

			color: $white;

      & > * ~ * {
        margin-top: 10px;
      }

			&--title {
				font-size: 2.5rem;
        font-weight: 700;
			}

			&__list {
				@include flex(unset, center);

				& > * ~ * {
					margin-left: 15px;
				}

				&__item {
          @include flex(unset, center);
          font-size: 0.85rem;

					& > * ~ * {
						margin-left: 5px;
					}

          &--icon{
            color: $red-bold;
            font-size: 1.25rem;
          }
				}
			}
		}

		&__btns{
			& > * ~ * {
				margin-left: 10px;
			}
		}
	}

	.slick-slider{
		.slick-active.slick-current {
			.hero {
				&-item {
					&__content {
						transform: translateY(-50%);
					}
				}
			}
		}
	
		.slick-dots{
			position: absolute;
			bottom: 3%;
			& li{
				border-radius: 999px;
				background-color: rgba($white, .3);

				width: 10px;
				height: 10px;

				transition: all .5s ease;
			}
			& li.slick-active {
				background-color: rgba($color: $white, $alpha: 1.0);
				width: 20px;
			}

			& li button::before{
				content: '';
			}
		}

	}
}

@media screen and (max-width: 960px) {
  .hero{
		display: none;
	}
}