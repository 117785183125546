*{
  margin: 0;
  padding: 0;

  border: 0;

  outline: none;

  box-sizing: border-box;
}

a{
  text-decoration: none;
}

li{
  list-style: none;
}

img{
  max-width: 100%;
}