@use 'assets/styles/' as *;

.carousel-section{
  margin-top: 3rem;

  &__header{
    @include flex(space-between, center);

    &--left{
      @include flex(unset, center);
      gap: 25px;
    }

    @media screen and (max-width: 768px){
      flex-direction: column;

      &--left{
        flex-direction: column;
        margin-bottom: 1.5rem;
      }
    }
  }

  &__selection{
    @include flex(unset, center);

    border: 1px solid $primary;
    border-radius: 999px;

    &--anchor{
      padding: 5px 20px;

      cursor: pointer;

      background-color: $white;
      border-radius: inherit;

      transition: all .5s ease;

      &.selected{
        color: $white;

        background-color: $red-bold;

        border-radius: 999px;

        padding: 5px 30px;
      }
    }
  }
}

// custom swiper
.swiper-container{
  & > * ~ * {
    margin-top: 10px;
  }

	width: 320px;

	@media screen and (min-width: 320px){
		width: 320px;
	}

	@media screen and (min-width: 560px){
		width: 560px;
	}

	@media screen and (min-width: 768px){
		width: 768px;
	}

	@media screen and (min-width: 1024px){
		width: 1024px;
	}
}
.swiper-wrapper{
  padding: 1.5rem 0 1.75rem;
}
.swiper-scrollbar{
  height: 7px
}
// .swiper-slide{
//   width: auto;
// }
// .swiper-scrollbar-drag{
//   width: 200px !important;
// }