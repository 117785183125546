@use 'assets/styles/' as *;

#sidebar {
	max-width: $sidebar-max-width;
	min-width: $sidebar-min-width;
	width: 100%;

	flex: 1;

	height: 100vh;
	padding: 1rem 1.5rem;

	border-right: 2px solid rgba($color: $secondary, $alpha: 0.3);

	@media screen and (max-width: 960px) {
		display: none;
	}

	&__header {
		@include flex(unset, center);
		height: $navbar-height;
		gap: 10px;

		&__image img {
			height: 45px;
			object-fit: cover;

			border-radius: 999px;
		}
	}

	&__content {
		& > * ~ * {
			margin-top: 20px;
		}
	}
}
