@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Readex+Pro:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.navbar{height:80px;display:flex}.navbar>*~*{margin-left:20px}.navbar__logo{display:flex;justify-content:unset;align-items:center}.navbar__logo--link{display:flex;justify-content:unset;align-items:center}.navbar__logo img{height:40px}.navbar__list{height:100%;display:flex;justify-content:unset;align-items:center}.navbar__list>*~*{margin-left:15px}.navbar__list__item{position:relative}.navbar__list__item::after{content:"";position:absolute;width:0%;left:50%;bottom:0;border-bottom:2px solid #7d8695;transform:translateX(-50%);transition:width .3s ease}@media(hover: hover){.navbar__list__item:hover::after{width:100%}}.navbar__list__item__link{display:inline-block;color:#7d8695;font-weight:500;padding:5px 0}.navbar__list__item__link__active{color:#353f59}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}#sidebar{max-width:300px;min-width:200px;width:100%;flex:1 1;height:100vh;padding:1rem 1.5rem;border-right:2px solid rgba(125,134,149,.3)}@media screen and (max-width: 960px){#sidebar{display:none}}#sidebar__header{display:flex;justify-content:unset;align-items:center;height:80px;grid-gap:10px;gap:10px}#sidebar__header__image img{height:45px;object-fit:cover;border-radius:999px}#sidebar__content>*~*{margin-top:20px}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.sidebar__menu{font-weight:500}.sidebar__menu--title{color:#7d8695;font-size:.95rem}.sidebar__menu__list__item{margin-top:10px;display:block}.sidebar__menu__list__item--link{display:flex;justify-content:unset;align-items:center;grid-gap:10px;gap:10px;cursor:pointer;font-size:1.15rem;color:#353f59;transition:color .3s ease}.sidebar__menu__list__item--link.active{color:#e71c61}.sidebar__menu__list__item--link:hover:not(.sidebar__menu__list__item--link.active){color:rgba(231,28,97,.6)}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.card-item{width:100%;max-width:500px;min-width:200px}.card-item__poster{position:relative;height:350px;background-size:cover;background-repeat:no-repeat;border-radius:10px}.card-item__poster__media{position:absolute;bottom:3%;left:0;right:0;padding:0 1rem;display:flex;justify-content:space-between;align-items:center;font-size:2rem;color:#fff}.card-item__poster__media--rating{background-color:#fff;color:#353f59;font-size:.85rem;padding:2px 13px;border-radius:999px}.card-item__content{margin-top:10px;display:flex;justify-content:space-between;align-items:unset}.card-item__content__detail{width:80%}.card-item__content__detail--title{font-size:1rem;margin-bottom:7px;color:#353f59}.card-item__content__detail--info{display:flex;justify-content:unset;align-items:center;grid-gap:10px;gap:10px;color:#7d8695;font-size:.8rem}.card-item--reaction{font-size:1.5rem;color:#7d8695}.card-item--reaction.love{color:#e71c61}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.category_page__header__24vND{position:relative;height:300px;background-size:cover;background-repeat:no-repeat;background-position:center;border-radius:10px;display:flex;justify-content:center;align-items:center}.category_page__header__24vND::after{content:"";position:absolute;top:0;bottom:0;left:0;right:0;background:linear-gradient(to top, #353f59, rgba(255, 255, 255, 0));border-radius:inherit}.category_page__header__title__3ACGV{color:#fff;font-size:3rem;text-transform:uppercase;z-index:1}.category_page__list__items__2ohC5{display:grid;grid-gap:20px;gap:20px;grid-template-columns:repeat(auto-fill, minmax(200px, 1fr));margin-top:3rem}.category_page__list__pagination__1Chul{margin-top:4rem;display:flex;justify-content:center;align-items:center}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.detail-page__header{position:relative;height:300px;background-size:cover;background-repeat:no-repeat;background-position:center center;border-radius:10px}.detail-page__header::after{content:"";position:absolute;top:0;bottom:0;left:0;right:0;background-color:rgba(53,63,89,.7);border-radius:inherit}.detail-page__header__content{position:absolute;top:50%;left:3%;width:70%;transform:translateY(-50%);padding:1rem 0 2rem;z-index:1;color:#fff}.detail-page__header__content__title{font-size:2.5rem}.detail-page__header__content__description{font-size:.85rem;margin-bottom:.5rem;display:flex;justify-content:unset;align-items:center;grid-gap:10px;gap:10px}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.videos-list>*~*{margin-top:1rem}.videos-list__item__title{font-size:1.25rem}.videos-list__item__description{font-size:.8rem;color:#7d8695}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.credits-list{display:grid;grid-gap:30px;gap:30px;grid-template-columns:repeat(auto-fill, minmax(30%, 1fr));margin:1rem 0}.credits-list__item{display:flex;justify-content:unset;align-items:center;grid-gap:20px;gap:20px}.credits-list__item img{display:block;width:100%;max-width:100px;height:100px;border-radius:999px;object-fit:cover}.credits-list__item--original-name{font-size:1.2rem}.credits-list__item--character{color:#7d8695;font-size:.8rem;font-weight:300}
.btn{font-size:1rem;padding:11px 20px;border-radius:10px;cursor:pointer}.btn:active{transform:scale(0.9)}.btn-primary{color:#fff;background-color:#e71c61;transition:all .3s ease}.btn-primary:hover{box-shadow:3px 5px 10px 3px rgba(231,28,97,.5)}.btn-outline{color:#fff;position:relative;background-color:transparent}.btn-outline::before,.btn-outline::after{content:"";position:absolute;top:0;left:0;width:calc(100% - 4px);height:calc(100% - 4px);border:2px solid #fff;border-radius:10px;transition:all .3s ease}@media(hover: hover){.btn-outline:hover::before{transform:translate(-3px, -3px)}.btn-outline:hover::after{transform:translate(3px, 3px)}}.btn-outline-primary{color:#353f59;font-weight:600;border-radius:10px;border:2px solid #353f59}.small{font-size:.85rem;padding:7px 11px}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.reviews>*~*{margin-top:2rem}.reviews .review__item>*~*{margin-top:.5rem}.reviews .review__item__header{display:flex;justify-content:unset;align-items:center;grid-gap:10px;gap:10px}.reviews .review__item__header img{display:block;height:50px;width:50px;object-fit:cover;border-radius:999px}.reviews .review__item__header__detail--name{font-size:1rem}.reviews .review__item__header__detail--date{font-size:.8rem;color:#7d8695}.reviews .review__item__content{max-width:100%}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.detail-page{position:relative}.detail-page__content{padding:1.5rem 0}.detail-page__content>*~*{margin-top:2rem}.detail-page__section>*~*{margin-top:.75rem}.genres__list{display:flex;justify-content:unset;align-items:center;font-size:.75rem;color:#fff;width:80%;grid-gap:7px;gap:7px;flex-wrap:wrap;z-index:1}.genres__list__item{border-radius:999px;padding:5px 7px;position:relative}.genres__list__item::after{content:"";position:absolute;top:0;left:0;right:0;bottom:0;background-color:currentColor;opacity:.5;border-radius:inherit}

*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.carousel-section{margin-top:3rem}.carousel-section__header{display:flex;justify-content:space-between;align-items:center}.carousel-section__header--left{display:flex;justify-content:unset;align-items:center;grid-gap:25px;gap:25px}@media screen and (max-width: 768px){.carousel-section__header{flex-direction:column}.carousel-section__header--left{flex-direction:column;margin-bottom:1.5rem}}.carousel-section__selection{display:flex;justify-content:unset;align-items:center;border:1px solid #353f59;border-radius:999px}.carousel-section__selection--anchor{padding:5px 20px;cursor:pointer;background-color:#fff;border-radius:inherit;transition:all .5s ease}.carousel-section__selection--anchor.selected{color:#fff;background-color:#e71c61;border-radius:999px;padding:5px 30px}.swiper-container{width:320px}.swiper-container>*~*{margin-top:10px}@media screen and (min-width: 320px){.swiper-container{width:320px}}@media screen and (min-width: 560px){.swiper-container{width:560px}}@media screen and (min-width: 768px){.swiper-container{width:768px}}@media screen and (min-width: 1024px){.swiper-container{width:1024px}}.swiper-wrapper{padding:1.5rem 0 1.75rem}.swiper-scrollbar{height:7px}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.modal{position:absolute;top:50%;left:50%;width:0;height:0;transform:translate(-50%, -50%);background-color:rgba(125,134,149,.9);z-index:99;border-radius:10px;opacity:0;visibility:hidden;transition:all .3s ease}.modal.active{width:70%;height:600px;visibility:visible;opacity:1}.modal__content{position:relative;height:100%;width:100%;border-radius:inherit;color:#fff}.modal__content__close{font-size:2rem;position:absolute;top:.5rem;right:.5rem;cursor:pointer}.modal__content iframe{position:absolute;left:50%;top:50%;width:90%;height:90%;transform:translate(-50%, -50%)}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}.hero-item{position:relative;width:100%;height:500px}.hero-item::before{content:"";position:absolute;left:0;top:0;width:100%;height:100%;border-radius:10px;background:linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.95) 100%)}.hero-item__image{display:block;width:100%;height:100%;object-fit:cover;object-position:center;border-radius:10px}.hero-item__content{position:absolute;top:50%;left:5%;width:70%;transform:translateY(100%);transition:all 1.5s ease;color:#fff}.hero-item__content>*~*{margin-top:10px}.hero-item__content--title{font-size:2.5rem;font-weight:700}.hero-item__content__list{display:flex;justify-content:unset;align-items:center}.hero-item__content__list>*~*{margin-left:15px}.hero-item__content__list__item{display:flex;justify-content:unset;align-items:center;font-size:.85rem}.hero-item__content__list__item>*~*{margin-left:5px}.hero-item__content__list__item--icon{color:#e71c61;font-size:1.25rem}.hero-item__btns>*~*{margin-left:10px}.hero .slick-slider .slick-active.slick-current .hero-item__content{transform:translateY(-50%)}.hero .slick-slider .slick-dots{position:absolute;bottom:3%}.hero .slick-slider .slick-dots li{border-radius:999px;background-color:rgba(255,255,255,.3);width:10px;height:10px;transition:all .5s ease}.hero .slick-slider .slick-dots li.slick-active{background-color:#fff;width:20px}.hero .slick-slider .slick-dots li button::before{content:""}@media screen and (max-width: 960px){.hero{display:none}}
*{margin:0;padding:0;border:0;outline:none;box-sizing:border-box}a{text-decoration:none}li{list-style:none}img{max-width:100%}body{font-family:"Readex Pro","Roboto",sans-serif;font-weight:400;color:#353f59;background-color:#fff}.uppercase{text-transform:uppercase}.App{display:flex;justify-content:space-between;align-items:stretch}.wrapper{display:flex;justify-content:center;flex:3 1}.main{padding:0 3rem;max-width:1240px;width:100%;flex:3 1}.sticky{position:-webkit-sticky;position:sticky;top:0}.bx.bxs-star{color:#fa7b07}.section-title{text-transform:uppercase;font-size:1.5rem;border-left:5px solid #e71c61;padding-left:1rem}@media screen and (max-width: 768px){.section-title{border-left-color:transparent;border-bottom:5px solid #e71c61;padding:.5rem 1rem}}
