@use 'assets/styles/' as *;

.credits-list {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));

  margin: 1rem 0;

  &__item {
    @include flex(unset, center);
    gap: 20px;
    img {
      display: block;
      width: 100%;
      max-width: 100px;
      height: 100px;
      border-radius: 999px;
      object-fit: cover;
    }
    &--original-name {
      font-size: 1.2rem;
    }
    &--character {
      color: $secondary;
      font-size: 0.8rem;
      font-weight: 300;
    }
  }
}