@use 'assets/styles/' as *;

.sidebar__menu {
	font-weight: 500;

	&--title {
		color: $secondary;
		font-size: .95rem;
	}

	&__list{
		
		&__item {
			margin-top: 10px;
			display: block;
	
			&--link {
				@include flex(unset, center);
				gap: 10px;
	
				cursor: pointer;
	
				font-size: 1.15rem;
				color: $primary;
	
				transition: color .3s ease;
	
				&.active{
					color: $red-bold;
				}
	
				&:hover:not(&.active){
					color: rgba($color: $red-bold, $alpha: .6);
				}
	
			}
	
		}
	}

}
